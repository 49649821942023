var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "work-allocation-table-card", attrs: { "no-body": "" } },
    [
      _c("b-table", {
        attrs: {
          responsive: "",
          hover: "",
          items: _vm.data,
          fields: _vm.fields,
          selectable: "",
          "select-mode": "single",
          "head-variant": "light",
          "table-class": "work-allocation-table",
          "sticky-header": "",
        },
        scopedSlots: _vm._u([
          {
            key: "cell(completed)",
            fn: function (data) {
              return [
                data.item.completed
                  ? _c("b-badge", { attrs: { variant: "outline-danger" } }, [
                      _vm._v("Lodged"),
                    ])
                  : _c("b-badge", { attrs: { variant: "outline-primary" } }, [
                      _vm._v("Not lodged"),
                    ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }