<template>
  <b-card no-body class="work-allocation-letter-card">
    <b-card-body>
      <b-container>
        <span v-if="data.completed" :class="{ 'stamp is-approved': data.completed }">
          {{ $t('work-allocations.letter.completed') }}
        </span>
        <div
          v-html="
            $t('work-allocations.letter.content', {
              sitename: $t('general.sitename'),
              'policy-type': data.policyType,
              'claimed-type': data.claimedType,
              'claim-day': data.claimDay,
              'claim-amount': formatPayment(data.claimAmount),
              'policy-number': data.policyNumber,
              'policy-name': data.policyName,
              'no-of-items': data.noOfItems,
              'claimed-items': data.claimedItems,
              'given-name': data.givenName,
              surname: data.surname,
              'street-address': data.streetAddress,
              city: data.city,
              state: data.state,
              'zip-code': data.zipCode,
              'telephone-number': data.telephoneNumber
            })
          "
        ></div>
      </b-container>
    </b-card-body>
  </b-card>
</template>

<script>
import { formatPayment } from '@/constants/config'
export default {
  props: ['data'],
  data() {
    return {
      appTitle: config.VUE_APP_CLIENT || process.env.VUE_APP_CLIENT
    }
  },
  methods: {
    formatPayment(value) {
      return formatPayment(value)
    }
  }
}
</script>
