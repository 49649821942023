<template>
  <div class="allocations">
    <div
      class="m-5"
      v-if="workAllocations && openTeamWeek.work_complexity === 1 && inCurrentWeek && !initData"
    >
      <b-row class="h-100">
        <b-col class="col-12">
          <work-allocation-table
            :data="workAllocations.claimMasters"
            :fields="workAllocationLetterFields"
          ></work-allocation-table>
        </b-col>
      </b-row>
    </div>
    <div
      class="container"
      v-if="workAllocations && openTeamWeek.work_complexity === 2 && inCurrentWeek && !initData"
    >
      <b-row class="h-100">
        <b-colxx xxs="12" md="10" lg="10" class="mx-auto my-auto">
          <b-row>
            <b-col cols lg="12" md="12" sm="12">
              <div class="position-absolute slide-pagination">
                <b-pagination
                  v-model="paginationIndex"
                  :value="paginationIndex"
                  :total-rows="workAllocations.claimMasters.length"
                  :per-page="perPage"
                  @change="goSlide"
                  align="center"
                ></b-pagination>
              </div>
              <carousel-3d
                id="carousel"
                ref="carousel"
                :key="`work-allocation-letter-${updatedComponent}`"
                :inverseScaling="options.inverseScaling"
                :perspective="options.perspective"
                :space="options.space"
                :loop="options.loop"
                :height="options.height"
                :width="options.width"
                :display="options.display"
                :minSwipeDistance="650"
                @after-slide-change="afterSlideChange"
              >
                <slide
                  v-for="(item, index) in workAllocations.claimMasters"
                  :key="item.id"
                  :index="index"
                  :id="item.id"
                >
                  <work-allocation-letter :data="item"></work-allocation-letter>
                </slide>
              </carousel-3d>
            </b-col>
          </b-row>
        </b-colxx>
      </b-row>
    </div>
    <b-container
      class="vh-100"
      v-if="workAllocations && workAllocations.length === 0 && inCurrentWeek && !initData"
    >
      <b-row class="h-50 text-center" align-v="center">
        <b-col cols lg="6" md="6" sm="12" class="mx-auto img-container">
          <img src="/assets/img/svg/question.svg" alt="no record" />
          <h1>No work allocation found</h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="vh-100" v-if="!inCurrentWeek && !initData">
      <b-row class="h-50 text-center" align-v="center">
        <b-col cols lg="6" md="6" sm="12" class="mx-auto img-container">
          <img src="assets/img/svg/digital_nomad.svg" alt="no open day" />
          <h1>No open day yet</h1>
        </b-col>
      </b-row>
    </b-container>

    <ring-loader :loading="initData" :color="loader.color"></ring-loader>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import WorkAllocationLetter from '@/components/Common/WorkAllocationLetter'
import WorkAllocationTable from '@/components/Common/WorkAllocationTable'
import { workAllocationLetterFields } from '@/components/Common/config'
import { mapGetters, mapActions } from 'vuex'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import RingLoader from 'vue-spinner/src/RingLoader'

export default {
  components: {
    Carousel3d,
    Slide,
    WorkAllocationLetter,
    WorkAllocationTable,
    RingLoader
  },
  data() {
    return {
      options: {
        inverseScaling: 200,
        perspective: 0,
        space: 300,
        loop: false,
        height: 850,
        width: 600,
        display: 3
      },
      slideIndex: 0,
      perPage: 1,
      paginationIndex: 1,
      workAllocationLetterFields,
      inCurrentWeek: true,
      initData: true,
      loader: {
        color: '#2d7281'
      },
      loading: false,
      currentTeamWeek: null,
      updatedComponent: 0
    }
  },
  mixins: [dataPreloadMixin, pusherMixin],
  methods: {
    ...mapActions([
      'fetchOpenTeamWeek',
      'fetchWorkAllocations',
      'completeWorkAllocation',
      'updateWorkComplexity',
      'fetchAppConfiguration'
    ]),
    goSlide(index) {
      this.slideIndex = index - 1
      this.$refs.carousel.goSlide(this.slideIndex)
    },
    afterSlideChange(index) {
      this.slideIndex = index
      this.paginationIndex = index + 1
    },
    async getWorkAlloctions() {
      let self = this
      if (self.currentTeamWeek) {
        await self.fetchWorkAllocations(self.currentTeamWeek.open_week_id)
        self.initData = false
        self.inCurrentWeek = true
        self.updatedComponent += 1
        self.paginationIndex = 1
      } else {
        self.initData = false
        self.inCurrentWeek = false
      }
    },
    async getOpenTeamWeek() {
      let self = this
      await self.fetchOpenTeamWeek()
      self.currentTeamWeek = self.openTeamWeek
    },
    async getLodgementData() {
      let self = this
      self.loading = true
      await self.getOpenTeamWeek()
      await self.getWorkAlloctions()
      self.loading = false
    },
    async initiateWeekControlListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`week-control.${self.currentUser.teamId}`)
      await channel.bind(
        'week-control',
        () => {
          self.getLodgementData()
        },
        channel.unbind()
      )
    },
    async initiateDayControlListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`day-control.${self.currentUser.teamId}`)
      await channel.bind(
        'day-control',
        () => {
          self.getLodgementData()
        },
        channel.unbind()
      )
    },
    async initiateWorkAllocationListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`work-allocation.${self.currentUser.id}`)
      await channel.bind(
        'work-allocation',
        (data) => {
          if (data.allocation) {
            self.getWorkAlloctions()
          }

          // check if claim is completed and update record
          if (data.claim) {
            self.completeWorkAllocation(data.claim)
          }
        },
        channel.unbind()
      )
    },
    async initiateGameSettingsListener() {
      let self = this
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-settings.${self.currentUser.teamId}`)
      await channel.bind(
        'game-settings',
        (data) => {
          const workComplexity = parseInt(data.work_complexity)
          const currentWorkComplexity = parseInt(self.openTeamWeek.work_complexity)
          if (workComplexity !== currentWorkComplexity) {
            self.updateWorkComplexity(workComplexity)
          }
        },
        channel.unbind()
      )
    }
  },
  computed: {
    ...mapGetters(['workAllocations', 'currentUser', 'openTeamWeek', 'locale', 'currency'])
  },
  mounted() {
    let self = this
    self.initiateWeekControlListener()
    self.initiateDayControlListener()
    self.initiateWorkAllocationListener()
    self.initiateGameSettingsListener()
  },
  async created() {
    await this.fetchAppConfiguration()
    await this.getLodgementData()
  },
  beforeDestroy() {
    let self = this
    self.leaveChannels()
  }
}
</script>
