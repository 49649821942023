import { locale } from '@/constants/config'
export const workAllocationLetterFields = [
  'completed',
  'givenName',
  'surname',
  'policyNumber',
  { key: 'policyName', label: 'Policy Type' },
  'claimedType',
  'claimedItems',
  'noOfItems',
  { key: 'claimAmount', label: `Claim Amount (${locale.symbol})` }
]
