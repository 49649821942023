var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "allocations" },
    [
      _vm.workAllocations &&
      _vm.openTeamWeek.work_complexity === 1 &&
      _vm.inCurrentWeek &&
      !_vm.initData
        ? _c(
            "div",
            { staticClass: "m-5" },
            [
              _c(
                "b-row",
                { staticClass: "h-100" },
                [
                  _c(
                    "b-col",
                    { staticClass: "col-12" },
                    [
                      _c("work-allocation-table", {
                        attrs: {
                          data: _vm.workAllocations.claimMasters,
                          fields: _vm.workAllocationLetterFields,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.workAllocations &&
      _vm.openTeamWeek.work_complexity === 2 &&
      _vm.inCurrentWeek &&
      !_vm.initData
        ? _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "b-row",
                { staticClass: "h-100" },
                [
                  _c(
                    "b-colxx",
                    {
                      staticClass: "mx-auto my-auto",
                      attrs: { xxs: "12", md: "10", lg: "10" },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "", lg: "12", md: "12", sm: "12" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "position-absolute slide-pagination",
                                },
                                [
                                  _c("b-pagination", {
                                    attrs: {
                                      value: _vm.paginationIndex,
                                      "total-rows":
                                        _vm.workAllocations.claimMasters.length,
                                      "per-page": _vm.perPage,
                                      align: "center",
                                    },
                                    on: { change: _vm.goSlide },
                                    model: {
                                      value: _vm.paginationIndex,
                                      callback: function ($$v) {
                                        _vm.paginationIndex = $$v
                                      },
                                      expression: "paginationIndex",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "carousel-3d",
                                {
                                  key: `work-allocation-letter-${_vm.updatedComponent}`,
                                  ref: "carousel",
                                  attrs: {
                                    id: "carousel",
                                    inverseScaling: _vm.options.inverseScaling,
                                    perspective: _vm.options.perspective,
                                    space: _vm.options.space,
                                    loop: _vm.options.loop,
                                    height: _vm.options.height,
                                    width: _vm.options.width,
                                    display: _vm.options.display,
                                    minSwipeDistance: 650,
                                  },
                                  on: {
                                    "after-slide-change": _vm.afterSlideChange,
                                  },
                                },
                                _vm._l(
                                  _vm.workAllocations.claimMasters,
                                  function (item, index) {
                                    return _c(
                                      "slide",
                                      {
                                        key: item.id,
                                        attrs: { index: index, id: item.id },
                                      },
                                      [
                                        _c("work-allocation-letter", {
                                          attrs: { data: item },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.workAllocations &&
      _vm.workAllocations.length === 0 &&
      _vm.inCurrentWeek &&
      !_vm.initData
        ? _c(
            "b-container",
            { staticClass: "vh-100" },
            [
              _c(
                "b-row",
                {
                  staticClass: "h-50 text-center",
                  attrs: { "align-v": "center" },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mx-auto img-container",
                      attrs: { cols: "", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/assets/img/svg/question.svg",
                          alt: "no record",
                        },
                      }),
                      _vm._v(" "),
                      _c("h1", [_vm._v("No work allocation found")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.inCurrentWeek && !_vm.initData
        ? _c(
            "b-container",
            { staticClass: "vh-100" },
            [
              _c(
                "b-row",
                {
                  staticClass: "h-50 text-center",
                  attrs: { "align-v": "center" },
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "mx-auto img-container",
                      attrs: { cols: "", lg: "6", md: "6", sm: "12" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "assets/img/svg/digital_nomad.svg",
                          alt: "no open day",
                        },
                      }),
                      _vm._v(" "),
                      _c("h1", [_vm._v("No open day yet")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ring-loader", {
        attrs: { loading: _vm.initData, color: _vm.loader.color },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }