<template>
  <b-card no-body class="work-allocation-table-card">
    <b-table
      responsive
      hover
      :items="data"
      :fields="fields"
      selectable
      select-mode="single"
      head-variant="light"
      table-class="work-allocation-table"
      sticky-header
    >
      <template v-slot:cell(completed)="data">
        <b-badge v-if="data.item.completed" variant="outline-danger">Lodged</b-badge>
        <b-badge v-else variant="outline-primary">Not lodged</b-badge>
      </template>
    </b-table>
  </b-card>
</template>

<script>
export default {
  props: ['data', 'fields']
}
</script>
