import { mapGetters } from 'vuex'
export const pusherMixin = {
  methods: {
    async subscribeChannel(pusher, channelName) {
      await pusher.subscribe(channelName)
    },
    async unsubscribeChannel(pusher, channelName) {
      await pusher.unsubscribe(channelName)
    },
    async bindEvent(pusher, eventName) {
      return new Promise((resolve) => {
        pusher.bind(eventName, (data) => {
          resolve(data)
        })
      })
    },
    async joinChannels() {
      let self = this
      let pusher = window.Pusher
      await self.subscribeChannel(pusher, `week-control.${self.currentUser.teamId}`)
      await self.subscribeChannel(pusher, `day-control.${self.currentUser.teamId}`)
      await self.subscribeChannel(pusher, `game-control.${self.currentUser.teamId}`)
      await self.subscribeChannel(pusher, `work-allocation.${self.currentUser.id}`)
      await self.subscribeChannel(pusher, `game-settings.${self.currentUser.teamId}`)
    },
    async leaveChannels() {
      let self = this
      let pusher = window.Pusher
      await self.unsubscribeChannel(pusher, `week-control.${self.currentUser.teamId}`)
      await self.unsubscribeChannel(pusher, `day-control.${self.currentUser.teamId}`)
      await self.unsubscribeChannel(pusher, `game-control.${self.currentUser.teamId}`)
      await self.unsubscribeChannel(pusher, `work-allocation.${self.currentUser.id}`)
      await self.unsubscribeChannel(pusher, `game-settings.${self.currentUser.teamId}`)
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  beforeDestroy() {
    let self = this
    self.leaveChannels()
  }
}
